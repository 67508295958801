import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "push-info-to-service"
    }}>{`Push info to service`}</h1>
    <p>{`Pushing happens with script options in form of`}</p>
    <p>{`Code below will result in showing context as a string`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  uid: 'Hemmo Hemminki'
`}</code></pre>
    <p>{`Code below will result showing context as a link`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  uid: 'https://host/path?args#hash'
`}</code></pre>
    <p>{`Option below will result in showing context string as a clickable link`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`uid: {
  name: 'Hemmo Hemminki',
  url: 'https://host/profile-info-path'
}
`}</code></pre>
    <h3 {...{
      "id": "example-script"
    }}>{`Example script:`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<!-- Smilee service script start -->
<script type="text/javascript" charset="UTF-8">
  (function () {
    var x = document.createElement('script');
    x.src = 'https://saas.smilee.io/assets/javascripts/cobrowse.js';
    x.type = 'text/javascript';
    x.async = 'true';
    x.onload = x.onreadystatechange = function () {
      var rs = this.readyState;
      if (rs && rs != 'complete' && rs != 'loaded') return;
      try {
        Cobrowse.create({
          apiKey: "LQt/yocAfcWRAt...",
          uid: {
            name: 'Hemmo Hemminki',
            url: 'https://host/profile-info-path'
          }
        });
      } catch (e) {
        console.error('Failed loading Smilee script');
      }
    };
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(x, s);
  })();
</script>
<!-- Smilee service script end -->
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      